import React from 'react';
import { useWorkspace } from '../../../context/WorkspaceContext';

const TopVerticalBar = () => {
    const { workspaces, loading, error, activeWorkspace, changeActiveWorkspace } = useWorkspace();

    const handleWorkspaceClick = (workspace) => {
        changeActiveWorkspace(workspace);
    };

    const renderSkeleton = () => (
        <div className="flex items-center flex-col relative cursor-pointer">
            <div className="inline-block h-10 w-10 rounded-full bg-gray-500 opacity-50 animate-pulse"></div>
            <span className="bg-gray-500 h-4 w-20 mt-2 opacity-50 rounded-md animate-pulse"></span>
        </div>
    );

    return (
        <nav className="border-b px-4 py-2 shadow-lg sticky top-0 z-10 vertical-menu">
            <div className="container mx-auto flex items-center justify-between">
                <div className="flex items-center space-x-4">
                    <h1 className="text-2xl font-bold">
                        {activeWorkspace ? activeWorkspace.name : 'Dashboard'}
                    </h1>
                </div>
                <div className="flex items-center space-x-4">
                    {loading ? (
                        renderSkeleton()
                    ) : error ? (
                        <span>{error}</span>
                    ) : workspaces.length > 0 ? (
                        workspaces.map((workspace) => (
                            <div
                                key={workspace.id}
                                onClick={() => handleWorkspaceClick(workspace)}
                                className={`hover:text-gray-300 flex items-center flex-col relative cursor-pointer ${activeWorkspace && activeWorkspace.id === workspace.id ? 'text-blue-500' : ''}`}
                            >
                                <img
                                    src={workspace.image || `${process.env.PUBLIC_URL}/random/no-image.png`}
                                    alt={workspace.name}
                                    className="inline-block h-10 w-10 rounded-full"
                                />
                                <span>{workspace.name}</span>
                            </div>
                        ))
                    ) : (
                        <span className="text-gray-500">No workspaces found</span>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default TopVerticalBar;
