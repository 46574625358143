import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingIndicator from '../../../components/Tools/LoadingIndicator';
import useLocalStorage from 'use-local-storage';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Google = () => {
    const navigate = useNavigate();
    const hasRunOnceRef = useRef(false)
    const [activeWorkspace] = useLocalStorage('activeWorkspace');

    useEffect(() => {
        if (!hasRunOnceRef.current) {
            hasRunOnceRef.current = true;
            const broadcastChannel = new BroadcastChannel('socialsChannel');

            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            //Google Access Token
            if (code) {
                (async () => {
                    await google_generate_access_token(code);
                })();

                async function google_generate_access_token(code) {
                    try {
                        const response = await axios.post(BACKEND_API_URL + '/api/google/generate_access_token_google', { code, workspaceId: activeWorkspace.id }, { withCredentials: true });
                        //navigate('/dashboard/socials');
                        broadcastChannel.postMessage('callbackLogin');
                        window.close();
                        return response.data; // Return the data for chaining
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }
    }, [navigate]);

    return (
        <div className='flex justify-center items-center w-full h-full'>
            <LoadingIndicator />
        </div>)
}

export default Google