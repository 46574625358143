import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs'
import { MdCloudUpload } from 'react-icons/md';
import { BsInstagram, BsYoutube, BsTiktok, BsFacebook, BsThreeDotsVertical, BsTwitterX } from 'react-icons/bs';
import { RiDeleteBinLine, RiPencilLine } from 'react-icons/ri';
import Modal from '../Modal/Modal';
import DropdownButton from '../Tools/DropdownButton';

const Month = ({ month, userUploads, showHistory, showScheduled, refreshUserUploads }) => {
    return (
        <div className="flex-1 grid grid-cols-4 md:grid-cols-7">
            {month.map((row, i) => (
                <React.Fragment key={i}>
                    {row.map((day, idx) => (
                        <Day day={day} key={idx} rowIdx={i} userUploads={userUploads} showHistory={showHistory} showScheduled={showScheduled} refreshUserUploads={refreshUserUploads} />
                    ))}
                </React.Fragment>
            ))}
        </div>
    );
}

export default Month;


const Day = ({ day, rowIdx, userUploads, showHistory, showScheduled, refreshUserUploads }) => {
    const [showModal, setShowModal] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [matchingUploads, setMatchingUploads] = useState([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [clickedData, setClickedData] = useState({
        date: null,
        draft: null
    });
    const [deletedPost, setDeletedPost] = useState(null);

    const getCurrentDayClass = () => {
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
            ? "text-white font-semibold rounded-full px-2 py-1 bg-blue-500"
            : "";
    };

    const isPastDay = () => {
        return day.isBefore(dayjs(), 'day');
    };

    const closeModal = () => {
        setShowModal(false);

        if (isDeleteModalOpen === true) {
            setDeleteModalOpen(false);
            refreshUserUploads();
        }

        if (showModal === true) {
            setShowModal(false);
            refreshUserUploads();
        }
    };

    useEffect(() => {
        if (userUploads && userUploads.length > 0) {
            const matchingUploadsArray = [];
            userUploads.forEach((upload) => {
                upload.posts.forEach((post) => {
                    const uploadDate = post.schedule ? new Date(post.schedule) : new Date(post.updatedAt);
                    const dayDate = new Date(day.$d);

                    if (
                        uploadDate.getFullYear() === dayDate.getFullYear() &&
                        uploadDate.getMonth() === dayDate.getMonth() &&
                        uploadDate.getDate() === dayDate.getDate()
                    ) {
                        matchingUploadsArray.push(upload);
                    }
                });
            });
            setMatchingUploads(matchingUploadsArray);
        }
    }, [userUploads, day]);

    const IconForSocialMedia = ({ socialMedia }) => {
        switch (socialMedia) {
            case 'youtube':
                return <BsYoutube color="red" size={13} />;
            case 'instagram':
                return <BsInstagram color="#C13584" size={13} />;
            case 'tiktok':
                return <BsTiktok color="black" size={13} />;
            case 'facebook':
                return <BsFacebook color="blue" size={13} />;
            case 'x':
                return <BsTwitterX color="black" size={13} />;
            default:
                return null;
        }
    };

    const handleUploadButtonClick = () => {
        setShowModal(true);
        setClickedData({ date: day.$d });
    };

    const handleEditButtonClick = (draft) => {
        setClickedData({ draft });
        setShowModal(true);
    };

    const handleDeleteButtonClick = (upload) => {
        setDeleteModalOpen(true);
        setDeletedPost(upload);
    };

    return (
        <div className={`relative border  min-h-[150px] border-gray-200 flex flex-col h-full ${isPastDay() ? 'opacity-30 bg-gray-200 border-gray-300 shadow-inner' : 'lg:hover:border-gray-400'}`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <header className="flex flex-col items-center my-1">
                {rowIdx === 0 && (
                    <p className="text-sm mt-1">
                        {day.format("ddd").toUpperCase()}
                    </p>
                )}
                <p className={`text-sm text-center ${getCurrentDayClass()}`}>
                    {day.format("DD")}
                </p>
            </header>

            <div className={`flex-1 relative`}>
                <div className='flex flex-col overflow-y-auto max-h-[195px] overflow-x-hidden pb-5'>
                    {matchingUploads && matchingUploads.map((upload, index) => (
                        <div key={`${index}`} className={`flex w-[95%] min-h-[50px] max-h-[50px] mx-auto rounded-lg mb-2 cursor-pointer ${upload.status === 'scheduled' ? 'bg-yellow-300 hover:bg-yellow-500' : 'bg-green-200 hover:bg-green-500'}  ${upload.status === 'scheduled' && !showScheduled && 'hidden'} ${upload.status === 'uploaded' && !showHistory && 'hidden'}`}>
                            <div className='flex w-full p-1'>
                                <div className='flex flex-col flex-1'>
                                    {upload.posts[0].title && (
                                        <p className="text-xs mx-1 text-black font-bold truncate ">{upload.posts[0].title}</p>
                                    )}

                                    <div className='flex-1'>
                                        {upload.posts[0].description && (
                                            <p className="text-black text-xs mx-1 truncate">{upload.posts[0].description}</p>
                                        )}
                                    </div>


                                </div>
                                <div className='flex flex-col justify-between items-end'>
                                    {true && ( /*upload.status === 'scheduled' && upload.posts[0].schedule */
                                        <DropdownButton label={<BsThreeDotsVertical size={18} />} classes="!p-0 hover:bg-none" options={[
                                            { label: `Edit`, onClick: () => handleEditButtonClick(upload), icon: <RiPencilLine size={20} className="ml-5 text-blue-600 font-bold" /> },
                                            { label: `Delete`, onClick: () => handleDeleteButtonClick(upload), icon: <RiDeleteBinLine size={20} className="ml-5 text-blue-600 font-bold" /> },
                                        ]} />
                                    )}
                                    <div className='flex justify-end gap-1'>
                                        {upload.posts.map((post, i) => (
                                            <IconForSocialMedia socialMedia={post.uploadTo} key={i} />
                                        ))}
                                    </div>

                                </div>

                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {isHovered && !isPastDay() && (
                <div className="flex border-t border-gray-300 cursor-pointer text-[12px] justify-center items-center hover:text-blue-500 absolute bottom-.posts[0] bg-white w-full py-1" onClick={handleUploadButtonClick}>
                    Upload <MdCloudUpload size={20} color="black" className='ml-2' />
                </div>
            )}

            {showModal && (
                <Modal open={showModal} closeModal={closeModal} type={'VideoUploaderModal'} otherInfo={clickedData} />
            )}
            {isDeleteModalOpen && (
                <Modal open={isDeleteModalOpen} closeModal={closeModal} type={'VerifyDelete'} otherInfo={deletedPost} />
            )}
        </div>
    );
}
