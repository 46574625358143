// Schedule.js

import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import CalendarHeader from '../../../components/Calendar/CalendarHeader';
import Month from '../../../components/Calendar/Month';
import LoadingIndicator from '../../../components/Tools/LoadingIndicator';
import Week from '../../../components/Calendar/Week';
import { useWorkspace } from '../../../context/WorkspaceContext';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Schedule = () => {
    const { activeWorkspace } = useWorkspace();
    const [viewMode, setViewMode] = useState(localStorage.getItem('calendarViewMode') || 'month');
    const [showHistory, setShowHistory] = useState(true);
    const [showScheduled, setShowScheduled] = useState(true);
    const [showLoading, setShowLoading] = useState(true);
    const [uploads, setUploads] = useState([]);

    // Month View States
    const [currentMonth, setCurrentMonth] = useState(getMonth());
    const [monthIndex, setMonthIndex] = useState(new Date().getMonth());

    // Week View States
    const [currentWeek, setCurrentWeek] = useState(getWeek());
    const [weekStartDate, setWeekStartDate] = useState(dayjs().startOf('week'));

    const getUploads = useCallback(async () => {
        try {
            const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/drafts`, { params: { workspaceId: activeWorkspace.id }, withCredentials: true });
            const allUploaded = response.data.filter(
                (item) => item.status === 'uploaded' || item.status === 'scheduled'
            );
            setUploads(allUploaded);
            setShowLoading(false);
        } catch (error) {
            console.log(error);
        }
    }, [activeWorkspace]);

    useEffect(() => {
        getUploads();
    }, [activeWorkspace, getUploads]);

    useEffect(() => {
        localStorage.setItem('calendarViewMode', viewMode);
    }, [viewMode]);

    useEffect(() => {
        if (viewMode === 'month') {
            setCurrentMonth(getMonth(monthIndex));
        }
    }, [monthIndex, viewMode]);

    useEffect(() => {
        if (viewMode === 'week') {
            setCurrentWeek(getWeek(weekStartDate));
        }
    }, [weekStartDate, viewMode]);

    const refreshUserUploads = () => {
        getUploads();
    };

    const handlePrevMonth = () => {
        setMonthIndex((prev) => prev - 1);
    };

    const handleNextMonth = () => {
        setMonthIndex((prev) => prev + 1);
    };

    const handlePrevWeek = () => {
        setWeekStartDate((prev) => prev.subtract(1, 'week'));
    };

    const handleNextWeek = () => {
        setWeekStartDate((prev) => prev.add(1, 'week'));
    };

    const handleReset = () => {
        if (viewMode === 'month') {
            setMonthIndex(dayjs().month());
        } else {
            setWeekStartDate(dayjs().startOf('week'));
        }
    };

    return (
        <>
            {!showLoading ? (
                <div className="flex flex-col w-full md:w-[98%] md:m-5 p-2 dashboard-section min-h-screen overflow-x-hidden">
                    <CalendarHeader
                        viewMode={viewMode}
                        setViewMode={setViewMode}
                        monthIndex={monthIndex}
                        handlePrevMonth={handlePrevMonth}
                        handleNextMonth={handleNextMonth}
                        handleReset={handleReset}
                        weekStartDate={weekStartDate}
                        handlePrevWeek={handlePrevWeek}
                        handleNextWeek={handleNextWeek}
                        showHistory={showHistory}
                        showScheduled={showScheduled}
                        setShowHistory={setShowHistory}
                        setShowScheduled={setShowScheduled}
                    />

                    <div className="flex flex-1">
                        {viewMode === 'month' ? (
                            <Month
                                month={currentMonth}
                                userUploads={uploads}
                                showHistory={showHistory}
                                showScheduled={showScheduled}
                                refreshUserUploads={refreshUserUploads}
                            />
                        ) : (
                            <Week
                                week={currentWeek}
                                userUploads={uploads}
                                showHistory={showHistory}
                                showScheduled={showScheduled}
                                refreshUserUploads={refreshUserUploads}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <LoadingIndicator />
            )}
        </>
    );
};

export default Schedule;

// Helper functions
const getMonth = (month = dayjs().month()) => {
    month = Math.floor(month);
    const year = dayjs().year();
    const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
    let currentMonthCount = 0 - firstDayOfTheMonth;
    const daysMatrix = new Array(5).fill([]).map(() => {
        return new Array(7).fill(null).map(() => {
            currentMonthCount++;
            return dayjs(new Date(year, month, currentMonthCount));
        });
    });
    return daysMatrix;
};

function getWeek(weekStart = dayjs().startOf('week')) {
    return Array.from({ length: 7 }, (_, i) => weekStart.add(i, 'day'));
}