import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import useLocalStorage from 'use-local-storage';

const WorkspaceContext = createContext();

export const useWorkspace = () => useContext(WorkspaceContext);

export const WorkspaceProvider = ({ children }) => {
    const [workspaces, setWorkspaces] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeWorkspace, setActiveWorkspace] = useLocalStorage('activeWorkspace', null);

    const fetchWorkspaces = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_BACKEND_API_URL + '/api/auth/user/workspaces', { withCredentials: true });
            setWorkspaces(res.data);
        } catch (err) {
            console.error("Error fetching workspaces:", err);
            setError('Failed to load workspaces');
        } finally {
            setLoading(false);
        }
    };

    const changeActiveWorkspace = (workspace) => {
        setActiveWorkspace(workspace);
    };

    useEffect(() => {
        fetchWorkspaces();
    }, []);

    return (
        <WorkspaceContext.Provider value={{ workspaces, loading, error, activeWorkspace, fetchWorkspaces, changeActiveWorkspace }}>
            {children}
        </WorkspaceContext.Provider>
    );
};