import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Button } from '../../ui/button';

const DropdownButton = ({ label, type, options, classes = '' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownStyle, setDropdownStyle] = useState({}); // dynamically set style
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            adjustDropdownPosition();
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const adjustDropdownPosition = () => {
        if (dropdownRef.current && buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect();
            const viewportWidth = window.innerWidth;

            // Default dropdown position is below the button
            let positionStyle = {
                top: `${buttonRect.bottom + window.scrollY}px`,
                left: `${buttonRect.left + window.scrollX}px`,
                position: 'absolute',
                zIndex: 9999
            };

            // Adjust position if dropdown would be clipped by screen edges
            const dropdownWidth = 200; // Set this to your dropdown width (or calculate it dynamically)
            if (buttonRect.right + dropdownWidth > viewportWidth) {
                positionStyle.left = `${buttonRect.right - dropdownWidth + window.scrollX}px`;
            }

            setDropdownStyle(positionStyle);
        }
    };

    const menuOptions = options.filter(option => option.label);
    const footerOption = options.find(option => option.extra);

    return (
        <>
            <div className="relative inline-block" ref={buttonRef}>
                {type === 'button' ? (
                    <Button className={classes} onClick={toggleDropdown}>{label}</Button>
                ) : (
                    <div className={classes + 'cursor-pointer hover:bg-gray-100 hover:rounded-md'} onClick={toggleDropdown}>{label}</div>
                )}
            </div>

            {isOpen && createPortal(
                <div
                    ref={dropdownRef}
                    className="w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                    style={dropdownStyle}
                >
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {menuOptions.map((option, index) => (
                            <div
                                key={index}
                                className="block cursor-pointer px-4 py-2 text-sm hover:bg-gray-100 w-full text-left flex items-center"
                                role="menuitem"
                                style={{ color: option.color || 'gray' }}
                                onClick={() => {
                                    option.onClick();
                                    setIsOpen(false);
                                }}
                            >
                                {option.icon && <span className="mr-2">{option.icon}</span>}
                                {option.label}
                            </div>
                        ))}
                        {footerOption && (
                            <div className="border-t border-gray-200 px-4 py-2 text-[10px] text-gray-500 mt-2">
                                {footerOption.extra}
                            </div>
                        )}
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default DropdownButton;
