import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingIndicator from '../../../components/Tools/LoadingIndicator';
import useLocalStorage from 'use-local-storage';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const X = () => {
    const hasRunOnceRef = useRef(false)
    const navigate = useNavigate();
    const [activeWorkspace] = useLocalStorage('activeWorkspace');
    useEffect(() => {
        if (!hasRunOnceRef.current) hasRunOnceRef.current = true;
        else return;
        const broadcastChannel = new BroadcastChannel('socialsChannel');


        //save code from url when user loggs in with X
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        //authorizations returns code in URL, retrieve them
        if (code) {
            (async () => {
                try {
                    await xGenerateAccessToken(code);
                } catch (error) {
                    console.error('An error occurred:', error);
                }
            })();

            // METHODS

            async function xGenerateAccessToken(code) {
                //Generate X Access Token
                try {
                    const response = await axios.post(BACKEND_API_URL + '/api/x/generate_access_token', { code, state, workspaceId: activeWorkspace.id }, {
                        withCredentials: true,
                    });
                    broadcastChannel.postMessage('callbackLogin');
                    window.close();
                    return response.data; // Return the data for chaining
                } catch (error) {
                    console.log(error);
                }
            }
        }

    }, [navigate]);

    return (
        <div className='flex justify-center items-center w-full h-full'>
            <LoadingIndicator />
        </div>)
}

export default X;