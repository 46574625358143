import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WorkspaceProvider } from './context/WorkspaceContext'; // Import the WorkspaceProvider

// pages
import LoginPage from "./pages/Auth/Login";
import SignupPage from "./pages/Auth/Signup";

// Protected Pages
import Dashboard from "./pages/Protected/Dashboard/Dashboard";
import Settings from "./pages/Protected/Settings/Settings";
import Instagram from "./pages/Protected/Callbacks/Instagram";
import Google from "./pages/Protected/Callbacks/Google";
import Tiktok from "./pages/Protected/Callbacks/Tiktok";

// components
import VerticalNavbar from "./components/Navigation/VerticalNavbar/VerticalNavbar";
import HorizontalMobileNavbar from "./components/Navigation/HorizontalMobileNavbar/HorizontalMobileNavbar"; // Import the new component
import Socials from "./pages/Protected/Socials/Socials";
import Upload from "./pages/Protected/Content/Upload/Upload";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail";
import History from "./pages/Protected/History/History";
import LoadingIndicator from "./components/Tools/LoadingIndicator";
import Templates from "./pages/Protected/Templates/Templates";
import Facebook from "./pages/Protected/Callbacks/Facebook";
import { isMobile as checkIsMobile, handleResize, debounce } from './utils/utils'; // Import utility functions
import Schedule from "./pages/Protected/Schedule/Schedule";
import Support from "./pages/Protected/Support/Support";
import X from "./pages/Protected/Callbacks/X";
import TopVerticalBar from "./components/Navigation/TopVerticalBar/TopVerticalBar"; // Import the new component

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const App = () => {
  const [theme, setTheme] = useLocalStorage("theme");
  const [storedtheme, setStoredTheme] = useState("light");
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const hasRunOnceRef = useRef(false);

  const checkLoginStatus = async () => {
    axios
      .get(BACKEND_API_URL + "/api/auth/user/isLoggedIn", { withCredentials: true })
      .then((response) => {
        setUserLoggedIn(response.data.success);
        return response.data.user;
      })
      .catch((error) => {
        setUserLoggedIn(false);
        console.error("Error checking user login status:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!hasRunOnceRef.current) {
      checkLoginStatus();
      hasRunOnceRef.current = true;
    }
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(() => handleResize(setIsMobile), 100);
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    const getUserSettings = async () => {
      try {
        const response = await axios.get(BACKEND_API_URL + "/api/auth/user/settings", {
          withCredentials: true, // Include this option if you need cookies
        });
        setTheme(response.data.theme);
        setStoredTheme(response.data.theme);
        return response.data; // Return the data for chaining
      } catch (error) {
        console.log(error);
      }
    };

    if (!hasRunOnceRef.current && userLoggedIn === true) {
      getUserSettings();
      hasRunOnceRef.current = true;
    }
  }, [setTheme, userLoggedIn]);

  // Show loading component on page load
  if (isLoading) {
    return <LoadingIndicator showLogo={true} className="h-screen w-screen" />;
  }

  return (
    <div className="App min-h-screen" data-theme={`${theme}`}>
      <BrowserRouter>
        <WorkspaceProvider> {/* Wrap your components with WorkspaceProvider */}
          <ToastContainer
            position="bottom-right"
            autoClose={7000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme={storedtheme}
          />

          <div className={`pages flex ${isMobile && 'flex-col'}`}>
            {userLoggedIn && !isMobile && <VerticalNavbar />}
            <div className="flex-1 overflow-auto">
              {userLoggedIn && <TopVerticalBar />}
              <Routes>
                <Route
                  path="/"
                  element={
                    userLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
                  }
                />
                <Route path="/confirm/:token" element={<ConfirmEmail />} />
                {/* PROTECTED ROUTES */}
                {userLoggedIn === true ? (
                  <>
                    <Route
                      path="/dashboard"
                      element={<Dashboard checkLoginStatus={checkLoginStatus} />}
                    />
                    <Route path="/dashboard/upload" element={<Upload />} />
                    <Route path="/dashboard/schedule" element={<Schedule />} />
                    <Route path="/dashboard/templates" element={<Templates />} />
                    <Route path="/dashboard/posts" element={<History />} />
                    <Route path="/dashboard/socials" element={<Socials />} />
                    <Route path="/dashboard/settings" element={<Settings />} />
                    <Route path="/dashboard/support" element={<Support />} />

                    {/* CALLBACK */}
                    <Route path="/callback/instagram" element={<Instagram />} />
                    <Route path="/callback/google" element={<Google />} />
                    <Route path="/callback/tiktok" element={<Tiktok />} />
                    <Route path="/callback/facebook" element={<Facebook />} />
                    <Route path="/callback/x" element={<X />} />
                  </>
                ) : (
                  // Unauthenticated Routes
                  <>
                    <Route
                      path="/login"
                      element={<LoginPage checkLoginStatus={checkLoginStatus} />}
                    />
                    <Route path="/dashboard/*" element={<Navigate to="/login" />} />
                    <Route
                      path="/signup"
                      element={<SignupPage checkLoginStatus={checkLoginStatus} />}
                    />
                  </>
                )}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
            {userLoggedIn && isMobile && <HorizontalMobileNavbar />}
          </div>
        </WorkspaceProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
