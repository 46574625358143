import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

const Breadcrumbs = () => {
    const location = useLocation();
    const paths = location.pathname.split('/').filter(x => x);

    return (
        <nav className="flex items-center text-background opacity-70 pt-3">
            {paths.map((path, index) => {
                const routeTo = `/${paths.slice(0, index + 1).join('/')}`;
                const isLast = index === paths.length - 1;

                return (
                    <div key={index} className="flex items-center">
                        {index > 0 && <FaChevronRight className="mx-2 text-sm text-gray-400" />}
                        {isLast ? (
                            <span className='capitalize text-lg font-bold'>{path}</span>
                        ) : (
                            <Link to={routeTo} className="text-sm hover:text-blue-600 capitalize">
                                {path}
                            </Link>
                        )}
                    </div>
                );
            })}
        </nav>
    );
};

export default Breadcrumbs;
