import { useState, useEffect, useCallback } from "react";
import { Modal } from "../../../components/Modal/Modal";
import axios from 'axios'; // Import Axios
import { RiPencilLine, RiDeleteBinLine } from 'react-icons/ri';
import { formatCustomDate } from '../../../utils/date';
import SearchInput from "../../../components/Tools/SearchInput";
import Breadcrumbs from "../../../components/Tools/Breadcrumbs";
import DropdownButton from "../../../components/Tools/DropdownButton"; // Import DropdownButton
import { useWorkspace } from "../../../context/WorkspaceContext";


const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Templates = () => {
    const { activeWorkspace } = useWorkspace();
    const [isModalOpenHashtag, setIsModalOpenHashtag] = useState(false);
    const [isModalEditHashtag, setIsModalEditHashtag] = useState(false);
    const [isModalEditCaption, setIsModalEditCaption] = useState(false);
    const [isModalOpenCaption, setIsModalOpenCaption] = useState(false);

    const [refresh, setRefresh] = useState(false);
    const [hashtagTemplates, setHashtagTemplates] = useState([]);
    const [captionTemplates, setCaptionTemplates] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const getTemplates = useCallback(async () => {
        if (!activeWorkspace) return;
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/templates', { params: { workspaceId: activeWorkspace.id }, withCredentials: true });

            const hashtags = response.data.filter(template => template.template_type === 'hashtag');
            const captions = response.data.filter(template => template.template_type === 'caption');

            setHashtagTemplates(hashtags);
            setCaptionTemplates(captions);
        } catch (error) {
            console.log(error);
        }
    }, [activeWorkspace]);

    useEffect(() => {
        getTemplates();
    }, [refresh, activeWorkspace, getTemplates]);

    const closeModal = () => {
        setIsModalOpenHashtag(false);
        setIsModalEditHashtag(false);
        setIsModalOpenCaption(false);
        setIsModalEditCaption(false);
        setRefresh(!refresh); // Toggle the refresh state
    };

    const handleDelete = async (id, type) => {
        try {
            await axios.delete(BACKEND_API_URL + `/api/auth/user/templates/${id}`, { params: { workspaceId: activeWorkspace.id }, withCredentials: true });

            if (type === 'hashtag') {
                setHashtagTemplates((prevTemplates) => prevTemplates.filter((template) => template._id !== id));
            } else {
                setCaptionTemplates((prevTemplates) => prevTemplates.filter((template) => template._id !== id));
            }
        } catch (error) {
            console.error('Error deleting template:', error);
        }
    };

    const [hoveredId, setHoveredId] = useState(null);
    const [clickedId, setClickedId] = useState(null);

    const handleMouseEnter = (id) => {
        setHoveredId(id);
    };

    const handleMouseLeave = () => {
        setHoveredId(null);
    };

    const handleClick = (id) => {
        setClickedId(clickedId === id ? null : id); // Toggle the clicked state
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredHashtags = hashtagTemplates.filter(template =>
        template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        template.info.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredCaptions = captionTemplates.filter(template =>
        template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        template.info.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const dropdownOptions = [
        { label: 'Create Hashtag', onClick: () => setIsModalOpenHashtag(true) },
        { label: 'Create Caption', onClick: () => setIsModalOpenCaption(true) }
    ];

    return (
        <div className='dashboard-container w-[95%] md:w-[1300px] max-w-[100%] mx-auto'>
            <div className='mb-5 flex flex-col md:flex-row gap-3 w-full items-center justify-between'>
                <div className="w-full md:w-1/3">
                    <Breadcrumbs />
                    <p className="text-sm italic">Create your own templates of hashtag and captions, re-use them on your uploads.</p>
                </div>

                <div className="w-full md:w-[300px]">
                    <SearchInput value={searchTerm} onChange={handleSearchChange} />
                </div>

                <div className="w-full md:w-1/3 flex justify-end">
                    <DropdownButton label="Create Template" type={'button'} options={dropdownOptions} />
                </div>
            </div>

            <div className="flex flex-col gap-10">
                {/* Hashtags Section */}
                <div className="flex flex-col dashboard-section">
                    <div className='flex justify-between relative text-2xl font-semibold flex items-start mb-5'>
                        <div className="flex flex-col">
                            <h3>Hashtags</h3>
                        </div>
                    </div>
                    <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full min-h-[150px]`}>
                        {filteredHashtags.length === 0 ? (
                            <p className="text-center text-gray-500 text-center italic w-full">No hashtag templates created yet, create your first one...</p>
                        ) : (
                            filteredHashtags.map((item) => (
                                <div
                                    key={item._id}
                                    className="flex w-full justify-between cursor-pointer bg-white flex flex-col w-full px-2 hover:border-solid
                                        hover:border-blue-200 border-dashed border-2 border-gray-300"
                                    onMouseEnter={() => handleMouseEnter(item._id)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => handleClick(item._id)}
                                >
                                    <div className="flex justify-between">
                                        <p className="text-lg font-semibold">{item.name}</p>
                                        {hoveredId === item._id || clickedId === item._id ? (
                                            <div className="flex gap-3">
                                                <div
                                                    className="custom-div"
                                                    title="Edit Product"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setIsModalEditHashtag(item);
                                                    }}
                                                >
                                                    <RiPencilLine size={23} className="text-blue-400" />
                                                </div>
                                                <div
                                                    className="custom-div"
                                                    title="Delete Product"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDelete(item._id, 'hashtag');
                                                    }}
                                                >
                                                    <RiDeleteBinLine size={23} className="text-red-400" />
                                                </div>
                                            </div>
                                        ) : (
                                            <p className="text-lg font-semibold">{formatCustomDate(item.createdAt)}</p>
                                        )}
                                    </div>

                                    <div className="flex justify-center">
                                        <div
                                            key={item.createdAt}
                                            className="max-h-[130px] overflow-y-auto overflow-x-hidden w-full"
                                        >
                                            <div className="text-gray-900 text-[17px]">
                                                {item.info}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>

                <hr />

                {/* Captions Section */}
                <div className="flex flex-col dashboard-section">
                    <div className='flex justify-between relative text-2xl font-semibold flex items-start mb-5'>
                        <div className="flex flex-col">
                            <h3>Captions</h3>
                        </div>
                    </div>
                    <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full min-h-[200px]`}>
                        {filteredCaptions.length === 0 ? (
                            <p className="text-center text-gray-500 text-center italic w-full">No caption templates created yet, create your first one...</p>
                        ) : (
                            filteredCaptions.map((item) => (
                                <div
                                    key={item._id}
                                    className="flex w-full justify-between cursor-pointer bg-white flex flex-col w-full px-2 hover:border-solid
                                        hover:border-blue-200 border-dashed border-2 border-gray-300"
                                    onMouseEnter={() => handleMouseEnter(item._id)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => handleClick(item._id)}
                                >
                                    <div className="flex justify-between">
                                        <p className="text-lg font-semibold">{item.name}</p>
                                        {hoveredId === item._id || clickedId === item._id ? (
                                            <div className="flex gap-3">
                                                <div
                                                    className="custom-div"
                                                    title="Edit Product"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setIsModalEditCaption(item);
                                                    }}
                                                >
                                                    <RiPencilLine size={23} className="text-blue-400" />
                                                </div>
                                                <div
                                                    className="custom-div"
                                                    title="Delete Product"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDelete(item._id, 'caption');
                                                    }}
                                                >
                                                    <RiDeleteBinLine size={23} className="text-red-400" />
                                                </div>
                                            </div>
                                        ) : (
                                            <p className="text-lg font-semibold">{formatCustomDate(item.createdAt)}</p>
                                        )}
                                    </div>

                                    <div className="flex justify-center">
                                        <div
                                            key={item.createdAt}
                                            className="max-h-[130px] overflow-y-auto overflow-x-hidden w-full"
                                        >
                                            <div className="text-gray-900 text-[17px]">
                                                {item.info}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>

            {/* Modals */}
            {isModalEditHashtag && (
                <div className='flex-col items-center border-t-2 pt-3 absolute'>
                    <Modal open={isModalEditHashtag} closeModal={closeModal} type={'TemplatesModal'} otherInfo={{ type: 'edit_hashtag', item: isModalEditHashtag }} />
                </div>
            )}
            {isModalOpenCaption && (
                <div className='flex-col items-center border-t-2 pt-3 absolute'>
                    <Modal open={isModalOpenCaption} closeModal={closeModal} type={'TemplatesModal'} otherInfo={'caption'} />
                </div>
            )}
            {isModalEditCaption && (
                <div className='flex-col items-center border-t-2 pt-3 absolute'>
                    <Modal open={isModalEditCaption} closeModal={closeModal} type={'TemplatesModal'} otherInfo={{ type: 'edit_caption', item: isModalEditCaption }} />
                </div>
            )}
            {isModalOpenHashtag && (
                <div className='flex-col items-center border-t-2 pt-3 absolute'>
                    <Modal open={isModalOpenHashtag} closeModal={closeModal} type={'TemplatesModal'} otherInfo={'hashtag'} />
                </div>
            )}
        </div>
    );
}

export default Templates;
