import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaCheck, FaCog, FaMoon, FaRegTrashAlt, FaSave, FaSignOutAlt, FaSun, FaUsers, FaSpinner } from 'react-icons/fa';
import { MdEmail, MdManageAccounts, MdPhone, MdSettings, MdWarning } from "react-icons/md";
import { GoUnverified, GoVerified } from "react-icons/go";
import { LuActivity } from "react-icons/lu";

// Assets
import Tabs from '../../../components/Navigation/MenuTabs/Tabs';
import { Modal } from '../../../components/Modal/Modal';
import { toast } from 'react-toastify';
import { IoIosNotifications, IoMdAdd } from 'react-icons/io';
import { RiDeleteBinLine, RiPencilLine, RiUserAddLine } from 'react-icons/ri';
import { BiSolidDashboard } from 'react-icons/bi';
import Info from '../../../components/Tools/Info';
import { TiUserDelete } from "react-icons/ti";
import Pagination from '../../../components/Tools/Pagination';
import Breadcrumbs from '../../../components/Tools/Breadcrumbs';
import SearchInput from '../../../components/Tools/SearchInput';
import useLocalStorage from 'use-local-storage';
import DropdownButton from '../../../components/Tools/DropdownButton';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useWorkspace } from '../../../context/WorkspaceContext';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Settings = () => {
    const [userInfo, setUserInfo] = useState(null)
    const [isModalSecurityOpen, setIsModalSecurityOpen] = useState(false);
    const [isModalVerifyEmailOpen, setIsModalVerifyEmailOpen] = useState(false);
    const [userSettings, setUserSettings] = useState(null);
    const [isModalDeleteAccountOpen, setIsModalDeleteAccountOpen] = useState(false);
    const hasRunOnce = useRef(false);
    const { activeWorkspace } = useWorkspace();

    const tabs = [
        { id: 'account', label: 'Account', icon: <MdManageAccounts /> },
        //{ id: 'billing', label: 'Billing', icon: <MdOutlinePayment /> },
        { id: 'team', label: 'Team', icon: <FaUsers /> },
        { id: 'settings', label: 'Settings', icon: <FaCog /> },
    ];
    const [selectedTab, setSelectedTab] = useState('account'); // Default selected tab

    const openSecurityModal = (e) => {
        e.stopPropagation();
        setIsModalSecurityOpen(true);
    };

    const openVerifyEmailModal = (e) => {
        e.stopPropagation();
        setIsModalVerifyEmailOpen(true);
    };
    const openDeleteAccountModal = (e) => {
        e.stopPropagation();
        setIsModalDeleteAccountOpen(true)
    };

    const closeModal = () => {
        setIsModalSecurityOpen(false);
        setIsModalVerifyEmailOpen(false);
        setIsModalDeleteAccountOpen(false);
        getUser();
    };

    useEffect(() => {
        if (!hasRunOnce.current) {
            getUser();
            getUserSettings();
            hasRunOnce.current = true;
        }
    }, []);

    const getUser = async () => {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/getUser', {
                withCredentials: true,
            });
            setUserInfo(response.data);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }


    const handleTabChange = (tabId) => {
        setSelectedTab(tabId);
    };


    const getUserSettings = async () => {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/settings', { params: { workspaceId: activeWorkspace.id }, withCredentials: true });
            // Initialize default values
            const showGuide = response.data.showGuide ?? true;
            const showAnalytics = response.data.showAnalytics ?? true;
            const showTodo = response.data.showTodo ?? true;

            // Update user settings with defaults
            setUserSettings({
                ...response.data,
                showGuide,
                showAnalytics,
                showTodo
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    const [activeSection, setActiveSection] = useState(null);


    const handleMenuClick = (tabId) => {
        const sectionRef = document.getElementById(tabId);
        console.log(sectionRef);
        if (sectionRef) {
            // Scroll to the section element
            sectionRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className='dashboard-container max-w-[100%] w-[95%] lg:w-[1200px] mx-auto'>
            <div className="mb-3">
                <Breadcrumbs />
            </div>
            <div className='dashboard-section'>
                <div className='mb-5'>
                    <Tabs tabs={tabs} onTabChange={handleTabChange} activeTab={selectedTab} main={true} />
                </div>

                <div className='pt-3'>
                    {selectedTab === 'account' && (
                        <div className='flex'>
                            <div className='w-[15%] hidden sm:flex border-r-2 border-gray-200'>
                                <Sidebar from={selectedTab} setActiveSection={setActiveSection} handleMenuClick={handleMenuClick} />
                            </div>

                            <div className='flex flex-col flex-1'>
                                <ProfileRender userInfo={userInfo} closeModal={closeModal} isModalOpen2={isModalVerifyEmailOpen} openModal2={openVerifyEmailModal} isActive={activeSection === 'Personal'} isModalOpen3={isModalSecurityOpen} openModal3={openSecurityModal} />
                                <ActivityRender userSettings={userSettings} closeModal={closeModal} isModalOpen={isModalDeleteAccountOpen} openModal={openDeleteAccountModal} isActive={activeSection === 'Activity'} />
                                <DangerZoneRender userInfo={userInfo} closeModal={closeModal} isModalOpen={isModalDeleteAccountOpen} openModal={openDeleteAccountModal} isActive={activeSection === 'Danger'} />
                            </div>


                        </div>
                    )}

                    {selectedTab === 'team' && (
                        <div className='flex'>
                            <TeamSection userInfo={userInfo} />

                        </div>
                    )}


                    {selectedTab === 'billing' && (<></>)}

                    {selectedTab === 'settings' && (
                        <div className='flex'>
                            <div className='w-[15%] hidden sm:flex border-r-2 border-gray-200'>
                                <Sidebar from={selectedTab} setActiveSection={setActiveSection} handleMenuClick={handleMenuClick} />
                            </div>

                            <div className='flex flex-col flex-1'>
                                <DashboardSection userSettings={userSettings} isActive={activeSection === 'Dashboard'} />
                                <GeneralSection userSettings={userSettings} isActive={activeSection === 'General'} />
                                <NotificationsSection userSettings={userSettings} isActive={activeSection === 'Notifications'} />
                            </div>

                        </div>
                    )}

                </div>
            </div>
        </div>
    )


}
/**
 * COMPONTENTS SETTINGS:
 * - Profile
 * - Security
 */

const ProfileRender = ({ userInfo, closeModal, openModal2, isModalOpen2, isActive, isModalOpen3, openModal3 }) => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [confirmSubmit, setConfirmSubmit] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const [username, setUsername] = useLocalStorage("username", ""); // Using the hook for username
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (userInfo) {
            setName(userInfo.name || '');
            setEmail(userInfo.email || '');
            setPhone(userInfo.phone || '');
        }
    }, [userInfo]);



    const handleSignOut = () => {
        axios.get(BACKEND_API_URL + '/api/auth/user/logout', {
            withCredentials: true
        })
            .then(response => {
                navigate("/login");
            })
            .catch(error => {
                console.log(error);
            });
    }

    const [isActiveSection, setisActiveSection] = useState(false);
    useEffect(() => {
        setisActiveSection(isActive);

        if (isActive) {
            const timerId = setTimeout(() => {
                setisActiveSection(false);
            }, 2000);

            return () => clearTimeout(timerId);
        }
    }, [isActive]);


    const handleEdit = async (e) => {
        e.preventDefault();
        if (confirmSubmit) {
            setIsLoading(true);
            try {
                const response = await axios.patch(BACKEND_API_URL + '/api/auth/user/profile',
                    { name, email, phone },
                    { withCredentials: true }
                );
                if (response.status === 200) {
                    setChangesMade(false);
                    setConfirmSubmit(false);
                    toast.success('Your profile information has been updated successfully!');
                    if (name) {
                        setUsername(name);
                    }
                    console.log(name);

                } else {
                    console.error('Failed to send data to the API');
                    toast.error('Failed to update your profile information. Please try again later.');
                    setConfirmSubmit(false);
                }
            } catch (error) {
                toast.error('Failed to update your profile information. Please try again later.');
                setConfirmSubmit(false);
            } finally {
                setIsLoading(false);
            }
        } else {
            setConfirmSubmit(true);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setName(value);
        } else if (name === 'email') {
            setEmail(value);
        } else if (name === 'phone') {
            setPhone(value);
        }
        setChangesMade(true); // Set changes made state to true
    };

    return (
        <>
            <div id="Profile" className={`flex flex-col p-5 ${isActiveSection ? 'border-blink' : 'ease-in duration-500 transition-all '}`}>
                <div className="flex justify-between text-2xl font-semibold border-b-2 border-blue-500 pb-2">
                    <div>
                        Profile
                    </div>
                    <div onClick={handleSignOut} className='flex items-center px-2 cursor-pointer transform transform-translate duration-200 hover:bg-red-500 hover:text-white border-b-2 border-red-500 font-semibold text-sm w-[max-content]'>
                        Logout <FaSignOutAlt className="ml-2" />
                    </div>
                </div>

                {userInfo && (
                    <form onSubmit={handleEdit}>
                        <div className={`mt-5 flex flex-col w-max p-2`}>

                            <div className='text-[12px] flex justify-between flex flex-col mb-2'>
                                <div className='flex-1 flex items-center'>
                                    <MdEmail className='mr-1' />
                                    <span>NAME:</span>
                                </div>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={name}
                                    onChange={handleInputChange}
                                    className="border border-gray-300 text-background bg-transparent px-2 py-1 rounded-md w-[200px]"
                                    autoComplete="username"
                                />
                            </div>

                            <div className="text-[12px] flex justify-between flex flex-col mb-2">
                                <div className='flex-1 flex items-center'>
                                    <MdEmail className='mr-1' />
                                    <span>EMAIL:</span>
                                </div>

                                <div className='flex gap-2'>
                                    <input
                                        type="email"
                                        value={email}
                                        name="email"
                                        onChange={handleInputChange}
                                        className="border border-gray-300 px-2 text-background bg-transparent py-1 rounded-md w-[200px]"
                                        autoComplete="email"
                                    />
                                    <div className=''>
                                        {userInfo.verified === true ? (
                                            <>
                                                <GoVerified size={30} color='green' />
                                            </>
                                        ) : (
                                            <div className='hover:text-blue-500 hover:scale-105 transform cursor-pointer flex flex-col justify-center items-center' onClick={openModal2}>
                                                <GoUnverified size={25} />
                                                <p className='text-[9px] italic'>Get verified</p>
                                            </div>
                                        )}
                                    </div>

                                </div>

                            </div>

                            <div className='text-[12px] flex justify-between flex flex-col mb-2'>
                                <div className='flex-1 flex items-center'>
                                    <MdPhone className='mr-1' />
                                    <span>PHONE:</span>
                                </div>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={phone}
                                    onChange={handleInputChange}
                                    className="border border-gray-300 text-background bg-transparent px-2 py-1 rounded-md w-[200px]"
                                    autoComplete="phone"
                                />

                            </div>

                            <div className='text-[12px] flex justify-between flex flex-col mb-2'>
                                <div className='flex-1 flex items-center'>
                                    <MdPhone className='mr-1' />
                                    <span>PASSWORD:</span>
                                </div>

                                <div className='flex justify-between'>
                                    <input
                                        type="password"
                                        value={'***********'}
                                        className="border border-gray-300 px-2 py-1 rounded-md w-[200px]  opacity-50 disabled:cursor-not-allowed"
                                        disabled={true}
                                        autoComplete="current-password"
                                    />

                                </div>

                            </div>

                            {/* Submit button */}
                            <div className='flex'>
                                <button
                                    type='submit'
                                    className={`text-white flex items-center justify-between mt-3 ${changesMade ? '' : 'opacity-50 cursor-not-allowed'} ${confirmSubmit ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600'}`}
                                    disabled={!changesMade || isLoading}
                                >
                                    {isLoading ? (
                                        <FaSpinner className="animate-spin" />
                                    ) : confirmSubmit ? (
                                        <>
                                            <p>Confirm</p>
                                            <FaCheck className='ml-2' />
                                        </>
                                    ) : (
                                        <>
                                            <p>Save</p>
                                            <FaSave className='ml-2' />
                                        </>
                                    )}
                                </button>
                                <p className='--text-link hover:underline self-end ml-3 text-sm' onClick={openModal3}>Change password</p>
                            </div>
                        </div>
                    </form>

                )}



            </div>

            {isModalOpen3 && (
                <Modal open={isModalOpen3} closeModal={closeModal} type={'PasswordModal'} otherInfo={userInfo.email} />
            )}

            {isModalOpen2 && (
                <Modal open={isModalOpen2} closeModal={closeModal} type={'VerifyEmailModal'} otherInfo={userInfo.name} />
            )}

        </>
    );
}

export const DashboardSection = ({ isActive, userSettings }) => {
    const [isActiveSection, setisActiveSection] = useState(false);
    const [storedTheme, setStoredTheme] = useState('');
    const [activeGuide, setActiveGuide] = useState(false);
    const [somethingChanged, setSomethingChanged] = useState(false);
    const [activeTodo, setActiveTodo] = useState(false);
    const [theme, setTheme] = useLocalStorage('theme');
    const [activeAnalytics, setActiveAnalytics] = useState(false);
    const { workspaces, activeWorkspace } = useWorkspace();

    useEffect(() => {
        setisActiveSection(isActive);

        if (isActive) {
            const timerId = setTimeout(() => {
                setisActiveSection(false);
            }, 2000);

            return () => clearTimeout(timerId);
        }
    }, [isActive]);

    useEffect(() => {
        if (userSettings) {
            setStoredTheme(userSettings.theme)
            setActiveGuide(userSettings.showGuide)
            setActiveTodo(userSettings.showTodo)
            setActiveAnalytics(userSettings.showAnalytics);
        }
    }, [userSettings]);

    const toggleTheme = (clickedTheme) => {
        setStoredTheme(clickedTheme);
        if (userSettings && clickedTheme !== userSettings.theme) {
            setSomethingChanged(true);
            userSettings.theme = clickedTheme;
        }
    }

    const toggleGuide = () => {
        const active = !activeGuide;
        setActiveGuide(active);
        if (userSettings && active !== userSettings.showGuide) {
            setSomethingChanged(true);
            userSettings.showGuide = active;
        }
    }


    const toggleTodo = () => {
        const active = !activeTodo;
        setActiveTodo(active);

        if (userSettings && active !== userSettings.showTodo) {
            setSomethingChanged(true);
            userSettings.showTodo = active;
        }
    }

    const toggleAnalytics = () => {
        const active = !activeAnalytics;
        setActiveAnalytics(active);
        if (userSettings && active !== userSettings.showAnalytics) {
            setSomethingChanged(true);
            userSettings.showAnalytics = active;
        }
    };

    const handleSaveChanges = async () => {
        const obj = {
            showGuide: activeGuide,
            theme: storedTheme,
            showTodo: activeTodo,
            showAnalytics: activeAnalytics,
            workspaceId: activeWorkspace.id
        }
        setTheme(storedTheme);

        try {
            const response = await axios.post(
                BACKEND_API_URL + '/api/auth/user/settings',
                obj,
                { withCredentials: true }
            );

            if (response.status === 201) {
                setSomethingChanged(false);
            } else {
                console.error('Failed to deactivate member');
                return false;
            }
        } catch (error) {
            console.error('Error deactivating member:', error);
            return false;
        }

    };

    return (
        <div id="Dashboard" className={`flex border-black flex-col p-5 mb-5 ${isActiveSection ? 'border-blink' : 'ease-in duration-500 transition-all '}`}>
            <div className='flex  justify-between items-end gap-5'>
                <div className="text-2xl font-semibold border-b-2 border-blue-500 pb-2 flex-1">
                    Dashboard
                </div>
                {somethingChanged && (
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={handleSaveChanges}>
                        Save Changes
                    </button>
                )}
            </div>

            <div className='flex flex-col mt-5'>
                <div className=''>
                    <p className='font-semibold'>Dashboard Theme</p>
                    <p className='text-[11px] mb-2'>Select the theme that fits your style</p>
                </div>

                <div className='flex gap-5'>
                    <div className={`flex flex-1 relative items-center p-3 rounded-lg mt-3 gap-2 --dark-theme-color cursor-pointer hover:scale-105 ${storedTheme === 'dark' ? '!border-blue-400 border-2' : ''}`} onClick={(e) => toggleTheme('dark')}>
                        <FaMoon />
                        Dark mode
                        {storedTheme === 'dark' && (
                            <div className='absolute right-[-10px] top-[-5px]'>
                                <FaCheck className='text-green-500' size={20} />
                            </div>
                        )}
                    </div>

                    <div className={`flex flex-1 relative items-center gap-2 p-3 rounded-lg mt-3 --light-theme-color cursor-pointer hover:scale-105 ${storedTheme === 'light' ? '!border-blue-500 border-2' : ''}`} onClick={(e) => toggleTheme('light')}>
                        <FaSun />
                        Light mode
                        {storedTheme === 'light' && (
                            <div className='absolute right-[-10px] top-[-5px]'>
                                <FaCheck className='text-green-500' size={25} />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* HIDE/SHOW GUIDE AND TODO */}
            <div className='flex flex-col lg:flex-row gap-2 mt-5'>
                <div className="flex flex-col w-[95%] md:w-[50%] border border-gray-300 p-3 rounded-lg">
                    <div className='flex items-center gap-2'>
                        <p className="mr-2 font-semibold">Guide/Onboarding</p>
                        <div className='w-max group rounded-full px-1 pt-1 hover:bg-blue-500'>
                            <Info type={'guide'} info={userSettings} />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-sm mt-2'>
                            Show our 5 steps "Get Started" guide for a fast setup of your account.
                        </p>

                        <label htmlFor="toggle" className="flex items-center cursor-pointer">
                            <div className={`relative ${activeGuide ? 'bg-indigo-600' : 'bg-gray-200'} rounded-full w-12 h-6 transition duration-200 ease-in-out`}>
                                <div className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full shadow-md transform ${activeGuide ? 'translate-x-6' : 'translate-x-0'} transition duration-200 ease-in-out`}></div>
                            </div>
                            <input
                                type="checkbox"
                                id="toggle"
                                className="sr-only"
                                checked={activeGuide}
                                onChange={toggleGuide}
                            />
                        </label>
                    </div>
                </div>

                <div className="flex flex-col w-[95%] md:w-[50%] border border-gray-300 p-3 rounded-lg">
                    <div className='flex items-center gap-2'>
                        <p className="mr-2 font-semibold">Todo</p>
                        <div className='w-max group rounded-full px-1 pt-1 hover:bg-blue-500'>
                            <Info type={'todo'} />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-sm mt-2'>
                            Show your created todo list.
                        </p>

                        <label htmlFor="toggle2" className="flex items-center cursor-pointer">
                            <div className={`relative ${activeTodo ? 'bg-indigo-600' : 'bg-gray-200'} rounded-full w-12 h-6 transition duration-200 ease-in-out`}>
                                <div className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full shadow-md transform ${activeTodo ? 'translate-x-6' : 'translate-x-0'} transition duration-200 ease-in-out`}></div>
                            </div>
                            <input
                                type="checkbox"
                                id="toggle2"
                                className="sr-only"
                                checked={activeTodo}
                                onChange={toggleTodo}
                            />
                        </label>
                    </div>
                </div>
            </div>

            {/* ANALYTICS */}
            <div className='flex flex-col lg:flex-row gap-2 mt-5'>
                <div className="flex flex-col w-[95%] md:w-[50%] border border-gray-300 p-3 rounded-lg">
                    <div className='flex items-center gap-2'>
                        <p className="mr-2 font-semibold">Analytics</p>
                        <div className='w-max group rounded-full px-1 pt-1 hover:bg-blue-500'>
                            <Info type={'analytics'} />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-sm mt-2'>
                            Show analytics section on your dashboard.
                        </p>

                        <label htmlFor="toggleAnalytics" className="flex items-center cursor-pointer">
                            <div className={`relative ${activeAnalytics ? 'bg-indigo-600' : 'bg-gray-200'} rounded-full w-12 h-6 transition duration-200 ease-in-out`}>
                                <div className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full shadow-md transform ${activeAnalytics ? 'translate-x-6' : 'translate-x-0'} transition duration-200 ease-in-out`}></div>
                            </div>
                            <input
                                type="checkbox"
                                id="toggleAnalytics"
                                className="sr-only"
                                checked={activeAnalytics}
                                onChange={toggleAnalytics}
                            />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

const NotificationsSection = ({ userSettings, isActive = false }) => {
    const [isActiveSection, setisActiveSection] = useState(false);
    const [isActiveNotifications, setisActiveNotifications] = useState(userSettings?.notifications?.isActive || false);
    const [errorNotifications, setErrorNotifications] = useState(userSettings?.notifications?.errorNotifications || false);
    const [socialAccountDisconnection, setSocialAccountDisconnection] = useState(userSettings?.notifications?.socialAccountDisconnection || false);
    const [somethingChanged, setSomethingChanged] = useState(false);
    const { activeWorkspace } = useWorkspace();
    const handleToggle = () => {
        const newValue = !errorNotifications;
        setErrorNotifications(newValue);
        setSomethingChanged(true);
    };

    const handleToggleDisconnection = () => {
        const newValue = !socialAccountDisconnection;
        setSocialAccountDisconnection(newValue);
        setSomethingChanged(true);
    };

    // general notifications, on off, set all other to off.
    const handleActivateNotifications = (active) => {
        if (active === false) {
            setErrorNotifications(false);
            setSocialAccountDisconnection(false);
        }
        setisActiveNotifications(active);
        setSomethingChanged(true);
    };

    useEffect(() => {
        setisActiveSection(isActive);

        if (isActive) {
            const timerId = setTimeout(() => {
                setisActiveSection(false);
            }, 2000);

            return () => clearTimeout(timerId);
        }
    }, [isActive]);


    const handleSaveChanges = async () => {
        const updated = {};
        updated.notifications = {
            isActive: isActiveNotifications,
            errorNotifications: errorNotifications,
            socialAccountDisconnection: socialAccountDisconnection
        };
        updated.workspaceId = activeWorkspace.id;

        try {
            const response = await axios.post(
                BACKEND_API_URL + '/api/auth/user/settings',
                updated,
                { withCredentials: true }
            );

            if (response.status === 201) {
                setSomethingChanged(false);
            } else {
                console.error('Failed to deactivate member');
                return false;
            }
        } catch (error) {
            console.error('Error deactivating member:', error);
            return false;
        }
    };

    return (
        <div id="Notifications" className={`flex flex-col p-5 ${isActiveSection ? 'border-blink' : 'ease-in duration-500 transition-all '}`}>
            <div className='flex  justify-between items-end gap-5'>
                <div className="text-2xl font-semibold border-b-2 border-blue-500 pb-2 flex-1">
                    Notifications
                </div>
                {somethingChanged && (
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={handleSaveChanges}>
                        Save Changes
                    </button>
                )}
            </div>
            <div className={`flex flex-col`}>
                <div className=''>
                    <p>Receive notifications</p>
                    <div className='text-sm mt-2'>
                        Activate notifications to receive alerts for actions within the application
                        <div className='flex pt-2'>
                            <div className='flex mr-5'>
                                <label htmlFor="activateNotifications" className=''>
                                    <input
                                        type="radio"
                                        name="notifications"
                                        value="activate"
                                        id="activateNotifications"
                                        checked={isActiveNotifications}
                                        onChange={() => handleActivateNotifications(true)}
                                        className='mr-2 self-center'
                                    />
                                    Activate</label>
                            </div>
                            <div className='flex'>
                                <label htmlFor="deactivateNotifications" className=''>
                                    <input
                                        type="radio"
                                        name="notifications"
                                        value="deactivate"
                                        id="deactivateNotifications"
                                        checked={!isActiveNotifications}
                                        onChange={() => handleActivateNotifications(false)}
                                        className='mr-2'
                                    />
                                    Deactivate</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${!isActiveNotifications && 'opacity-20 pointer-events-none'}`}>
                <div className="flex flex-col mt-3">
                    <div className="flex flex-col w-[95%] md:w-[50%] border border-gray-300 p-3 rounded-lg">
                        <p className="mr-2">Error notifications</p>
                        <div className="flex justify-between">
                            <p className='text-sm mt-2'>
                                Receive alerts for post upload errors to take immediate action.
                            </p>

                            <label htmlFor="toggleError" className="flex items-center cursor-pointer">
                                <div className={`relative ${errorNotifications ? 'bg-indigo-600' : 'bg-gray-200'} rounded-full w-12 h-6 transition duration-200 ease-in-out`}>
                                    <div className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full shadow-md transform ${errorNotifications ? 'translate-x-6' : 'translate-x-0'} transition duration-200 ease-in-out`}></div>
                                </div>
                                <input
                                    type="checkbox"
                                    id="toggleError"
                                    className="sr-only"
                                    checked={errorNotifications}
                                    onChange={handleToggle}
                                />
                            </label>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col mt-3">
                    <div className="flex flex-col w-[95%] md:w-[50%] border border-gray-300 p-3 rounded-lg">
                        <p className="mr-2">Social Account Disconnection</p>
                        <div className="flex justify-between">
                            <p className='text-sm mt-2'>
                                Get notified for upcoming social media logouts to avoid upload errors.
                                <br />
                                Social media connections expire after a period of time.
                            </p>

                            <label htmlFor="toggleDisconnection" className="flex items-center cursor-pointer">
                                <div className={`relative ${socialAccountDisconnection ? 'bg-indigo-600' : 'bg-gray-200'} rounded-full w-12 h-6 transition duration-200 ease-in-out`}>
                                    <div className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full shadow-md transform ${socialAccountDisconnection ? 'translate-x-6' : 'translate-x-0'} transition duration-200 ease-in-out`}></div>
                                </div>
                                <input
                                    type="checkbox"
                                    id="toggleDisconnection"
                                    className="sr-only"
                                    checked={socialAccountDisconnection}
                                    onChange={handleToggleDisconnection}
                                />

                            </label>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};


const Sidebar = ({ from, setActiveSection, handleMenuClick }) => {
    const sidebarRef = useRef(null);
    const initialTop = useRef(0);

    useEffect(() => {
        const handleScroll = () => {
            if (sidebarRef.current) {
                const offsetTop = initialTop.current;
                if (window.scrollY > offsetTop) {
                    sidebarRef.current.classList.add('fixed', 'top-0', 'z-10');
                } else {
                    sidebarRef.current.classList.remove('fixed', 'top-0', 'z-10');
                }
            }
        };

        // Set the initial top position
        initialTop.current = sidebarRef.current.offsetTop;

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    let menuItems = [];

    if (from === 'account') {
        menuItems = [
            { icon: <MdSettings />, text: 'Profile', color: 'blue' },
            { icon: <LuActivity />, text: 'Activity', color: 'blue' },
            { icon: <MdWarning />, text: 'Danger', color: 'blue' },
        ];
    }

    if (from === 'settings') {
        menuItems = [
            { icon: <BiSolidDashboard />, text: 'Dashboard', color: 'blue' },
            { icon: <MdSettings />, text: 'General', color: 'blue' },
            { icon: <IoIosNotifications />, text: 'Notifications', color: 'blue' },
        ];
    }

    const handleClick = (ref) => () => {
        setActiveSection(ref);
        handleMenuClick(ref);
    }

    return (
        <ul ref={sidebarRef} className="">
            {menuItems.map((item, index) => (
                <li key={index}>
                    <div
                        className={`flex items-center gap-2 px-4 py-3 cursor-pointer text-${item.color}-700`}
                        onClick={handleClick(item.text)}
                    >
                        {item.icon}
                        <span className="text-sm font-medium">{item.text}</span>
                    </div>
                </li>
            ))}
        </ul>
    );
};

function SkeletonWorkspace() {
    return (
        <div className="flex justify-between p-4 border border-gray-300 rounded-lg shadow-md w-full animate-pulse">
            <div className="flex flex-col items-center justify-center h-full">
                <div className="h-6 bg-gray-300 rounded w-3/4 mb-2 mx-auto"></div>
                <div className="flex items-center justify-center w-32 h-full bg-gray-300 rounded-md"></div>
            </div>
            <div className="flex flex-col items-center">
                <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
                <div className="w-full h-32 bg-gray-300 rounded-md mt-2"></div>
            </div>
            <div className="flex flex-col w-full pl-4">
                <div className="h-6 bg-gray-300 rounded w-1/2 mb-2"></div>
                <ul className="space-y-2">
                    <div className="flex flex-col gap-3">
                        <div className="h-10 bg-gray-300 rounded w-full"></div>
                        <div className="h-10 bg-gray-300 rounded w-full"></div>
                        <div className="h-10 bg-gray-300 rounded w-full"></div>
                    </div>
                </ul>
            </div>
        </div>
    );
}

const TeamSection = ({ userInfo }) => {
    const [openModalWorkspace, setOpenModalWorkspace] = useState(false);
    const [openModalAddTeam, setOpenModalAddTeam] = useState(false);
    const [openModalDeleteWorkspace, setOpenModalDeleteWorkspace] = useState(false);
    const [selectedWorkspace, setSelectedWorkspace] = useState(null);
    const { workspaces, fetchWorkspaces, loading } = useWorkspace();

    const handleCreateWorkspace = (e) => {
        e.preventDefault();
        setSelectedWorkspace(null); // Clear selected workspace for creation
        setOpenModalWorkspace(true);
    };

    const handleEditButtonClick = (workspace) => {
        setSelectedWorkspace(workspace);
        setOpenModalWorkspace(true);
    };

    const handleDeleteButtonClick = (workspace) => {
        setSelectedWorkspace(workspace);
        setOpenModalDeleteWorkspace(true);
    };

    const handleAddTeamMemberClick = (workspace) => {
        setSelectedWorkspace(workspace);
        setOpenModalAddTeam(true);
    };

    const closeModal = (refresh = false) => {
        setOpenModalAddTeam(false);
        setOpenModalWorkspace(false);
        setOpenModalDeleteWorkspace(false);
        if (refresh) {
            fetchWorkspaces();
        }
    };

    return (
        <div className="w-full mx-auto rounded-lg p-6 --theme-colors">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Workspace Management</h2>
                <button
                    className="flex items-center bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600"
                    onClick={handleCreateWorkspace}
                >
                    <IoMdAdd className="mr-2" /> Create New Workspace
                </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-4">
                {loading ? (
                    <>
                        <SkeletonWorkspace />
                        <SkeletonWorkspace />
                    </>
                ) : (
                    workspaces.map((workspace) => {
                        const currentUserRole = workspace.members.find(m => m.email === userInfo.email)?.role;
                        const isAuthorized = currentUserRole === 'owner' || currentUserRole === 'admin';

                        return (
                            <div key={workspace.id} className="flex items-start justify-between p-4 border border-gray-300 rounded-lg shadow-md">
                                <div className="flex flex-col items-center">
                                    <h3 className="text-lg font-semibold">{workspace.name}</h3>
                                    <img
                                        src={workspace.image || `${process.env.PUBLIC_URL}/random/no-image.png`}
                                        alt={workspace.name}
                                        className="w-full h-32 object-cover rounded-md mt-2"
                                    />
                                </div>
                                <div className="flex flex-col w-full pl-4">
                                    <h4 className="text-md font-semibold mb-2 text-gray-500">Team Members</h4>
                                    <ul className="space-y-2">
                                        <div className="flex flex-col gap-5">
                                            {workspace.members && workspace.members.length > 0 ? (
                                                workspace.members.map((member, index) => (
                                                    <li key={index} className="flex flex-col text-sm text-gray-500 border-b pb-2">
                                                        <div className="flex items-center">
                                                            <span className="font-medium text-gray-700">{member.username}</span>
                                                            <span className="ml-2 bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">{member.role}</span>
                                                        </div>
                                                        <span>Email: {member.email}</span>
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="text-sm text-gray-500">No team members yet.</li>
                                            )}
                                        </div>
                                    </ul>
                                </div>
                                {isAuthorized && (
                                    <DropdownButton
                                        label={<BsThreeDotsVertical size={18} />}
                                        type={'singleLeftItems'}
                                        classes="!p-0 hover:bg-none"
                                        options={[
                                            { label: `Edit workspace`, onClick: () => handleEditButtonClick(workspace), icon: <RiPencilLine size={20} className="ml-5 text-blue-600 font-bold" />, disabled: !isAuthorized },
                                            { label: `Add team member`, onClick: () => handleAddTeamMemberClick(workspace), icon: <RiUserAddLine size={20} className="ml-5 text-blue-600 font-bold" />, disabled: !isAuthorized },
                                            { label: `Delete workspace`, onClick: () => handleDeleteButtonClick(workspace), icon: <RiDeleteBinLine size={20} className="ml-5 text-blue-600 font-bold" />, disabled: !isAuthorized },
                                        ]}
                                    />
                                )}
                            </div>
                        );
                    })
                )}
            </div>
            {openModalAddTeam && (
                <Modal
                    open={openModalAddTeam}
                    closeModal={closeModal}
                    type={'AddTeamMemberModal'}
                    otherInfo={selectedWorkspace}
                />
            )}
            {openModalWorkspace && (
                <Modal
                    open={openModalWorkspace}
                    closeModal={closeModal}
                    type={'CreateWorkspaceModal'}
                    otherInfo={{ userInfo, workspace: selectedWorkspace }}
                />
            )}
            {openModalDeleteWorkspace && (
                <Modal
                    open={openModalDeleteWorkspace}
                    closeModal={closeModal}
                    type={'ConfirmDeleteWorkspace'}
                    otherInfo={selectedWorkspace}
                />
            )}
        </div>
    );
};

const GeneralSection = ({ isActive, userSettings }) => {
    const [selectedTimezone, setSelectedTimezone] = useState(userSettings?.timezone || 'UTC');
    const [somethingChanged, setSomethingChanged] = useState(false);
    const [isActiveSection, setisActiveSection] = useState(false);
    const { activeWorkspace } = useWorkspace();

    const handleTimezoneChange = (timezone) => {
        setSelectedTimezone(timezone);
        if (userSettings && userSettings.timezone !== timezone) {
            setSomethingChanged(true);
        }
    };

    const handleSaveChanges = async () => {
        const obj = {
            timezone: selectedTimezone,
            workspaceId: activeWorkspace.id
        }

        try {
            const response = await axios.post(
                BACKEND_API_URL + '/api/auth/user/settings',
                obj,
                { withCredentials: true }
            );
            if (response.status === 201) {
                setSomethingChanged(false);
            } else {
                console.error('Failed to deactivate member');
                return false;
            }
        } catch (error) {
            console.error('Error deactivating member:', error);
            return false;
        }

    };

    useEffect(() => {
        setisActiveSection(isActive);

        if (isActive) {
            const timerId = setTimeout(() => {
                setisActiveSection(false);
            }, 2000);

            return () => clearTimeout(timerId);
        }
    }, [isActive]);

    return (
        <div id="General" className={`flex flex-col p-5 mb-5 ${isActiveSection ? 'border-blink' : 'ease-in duration-500 transition-all '}`}>
            <div className='flex  justify-between items-end gap-5'>
                <div className="text-2xl font-semibold border-b-2 border-blue-500 pb-2 flex-1">
                    General Settings
                </div>
                {somethingChanged && (
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={handleSaveChanges}>
                        Save Changes
                    </button>
                )}
            </div>

            <div className='flex flex-col'>
                <div className=''>
                    <p className='font-semibold'>Timezone</p>
                    <p className='text-[11px] mb-2'>Set your preffered timezone</p>
                </div>

                <div className='flex items-end justify-between text-black'>
                    <Timezones currentTimezone={userSettings?.timezone} onTimezoneChange={handleTimezoneChange} />
                </div>
            </div>
        </div>
    )
}


const Timezones = ({ currentTimezone, onTimezoneChange }) => {
    const [selectedTimezone, setSelectedTimezone] = useState(currentTimezone || 'UTC');

    const handleChange = (event) => {
        const timezone = event.target.value;
        setSelectedTimezone(timezone);
        onTimezoneChange(timezone); // Call the callback function with the selected value
    };

    const tzList = {
        "Etc/GMT+12": "(GMT-12:00) International Date Line West",
        "Pacific/Midway": "(GMT-11:00) Midway Island, Samoa",
        "Pacific/Honolulu": "(GMT-10:00) Hawaii",
        "US/Alaska": "(GMT-09:00) Alaska",
        "America/Los_Angeles": "(GMT-08:00) Pacific Time (US & Canada)",
        "US/Arizona": "(GMT-07:00) Arizona",
        "America/Managua": "(GMT-06:00) Central America",
        "US/Central": "(GMT-06:00) Central Time (US & Canada)",
        "America/Bogota": "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
        "US/Eastern": "(GMT-05:00) Eastern Time (US & Canada)",
        "Canada/Atlantic": "(GMT-04:00) Atlantic Time (Canada)",
        "America/Argentina/Buenos_Aires": "(GMT-03:00) Buenos Aires, Georgetown",
        "America/Noronha": "(GMT-02:00) Mid-Atlantic",
        "Atlantic/Azores": "(GMT-01:00) Azores",
        "UTC": "(GMT+00:00) Dublin, Edinburgh, Lisbon, London",
        "Europe/Amsterdam": "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        "Europe/Helsinki": "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        "Europe/Moscow": "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
        "Asia/Tehran": "(GMT+03:30) Tehran",
        "Asia/Yerevan": "(GMT+04:00) Yerevan",
        "Asia/Kabul": "(GMT+04:30) Kabul",
        "Asia/Yekaterinburg": "(GMT+05:00) Yekaterinburg",
        "Asia/Karachi": "(GMT+05:00) Islamabad, Karachi, Tashkent",
        "Asia/Calcutta": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        "Asia/Katmandu": "(GMT+05:45) Kathmandu",
        "Asia/Dhaka": "(GMT+06:00) Astana, Dhaka",
        "Asia/Rangoon": "(GMT+06:30) Yangon (Rangoon)",
        "Asia/Bangkok": "(GMT+07:00) Bangkok, Hanoi, Jakarta",
        "Asia/Hong_Kong": "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
        "Asia/Seoul": "(GMT+09:00) Seoul",
        "Australia/Adelaide": "(GMT+09:30) Adelaide",
        "Australia/Canberra": "(GMT+10:00) Canberra, Melbourne, Sydney",
        "Asia/Magadan": "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
        "Pacific/Auckland": "(GMT+12:00) Auckland, Wellington",
        "Pacific/Tongatapu": "(GMT+13:00) Nuku'alofa"
    }


    return (
        <select
            name="timezone"
            className="w-full lg:w-1/2 overflow-hidden border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={selectedTimezone}
            onChange={handleChange}
        >
            <option value="" disabled>Select Timezone</option>
            {Object.entries(tzList).map(([timezone, label]) => (
                <option key={timezone} value={timezone}>
                    {label}
                </option>
            ))}
        </select>
    )
}

const DangerZoneRender = ({ userInfo, closeModal, isModalOpen, isActive, openModal }) => {
    const [isActiveSection, setisActiveSection] = useState(false);

    useEffect(() => {
        setisActiveSection(isActive);

        if (isActive) {
            const timerId = setTimeout(() => {
                setisActiveSection(false);
            }, 2000);

            return () => clearTimeout(timerId);
        }
    }, [isActive]);

    return (
        <div id='Danger' className={`flex flex-col p-5 gap-5 ${isActiveSection ? 'border-blink' : 'ease-in duration-500 transition-all '}`}>
            <div className='flex items-center gap-2 text-2xl font-semibold border-b-2 border-blue-500 pb-2'>
                Danger Zone <MdWarning className='text-yellow-500' />
            </div>

            <p className='text-sm'>
                This action will delete your SocialFlick account entirely even from our system, including videos, templates, history uploads, statistics and personal settings. <span className='underline'> We cannot help you recover your account!</span>
            </p>

            <div className='flex justify-between items-center bg-red-400 w-1/2 mx-auto shadow-md rounded-md'>
                <div className='flex gap-2 items-center'>
                    <div className='rounded-full shadow-inner p-2'>
                        <TiUserDelete size={30} />
                    </div>
                    <p>{userInfo?.name}</p>
                </div>

                <div className='rounded-sm p-2  mr-3 shadow-inner' onClick={openModal}>
                    <FaRegTrashAlt size={20} color='red' />
                </div>
            </div>

            {isModalOpen && (
                <Modal open={isModalOpen} closeModal={closeModal} type={'DeleteAccountModal'} otherInfo={userInfo} />
            )}
        </div>
    )
}

const ActivityRender = ({ userSettings, closeModal, isModalOpen, isActive, openModal }) => {
    const [isActiveSection, setIsActiveSection] = useState(false);
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [maxPage, setMaxPage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const { activeWorkspace } = useWorkspace();
    const itemsPerPage = 3;

    useEffect(() => {
        setIsActiveSection(isActive);

        if (isActive) {
            const timerId = setTimeout(() => {
                setIsActiveSection(false);
            }, 2000);

            return () => clearTimeout(timerId);
        }
    }, [isActive]);

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const response = await axios.get(BACKEND_API_URL + '/api/auth/user/logs', {
                    withCredentials: true,
                });
                if (response.data.success) {
                    setLogs(response.data.logs);
                }
            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        };

        fetchLogs();
    }, [activeWorkspace]);

    useEffect(() => {
        const filterLogs = () => {
            if (!searchTerm) {
                setFilteredLogs(logs);
            } else {
                const filtered = logs.filter(log =>
                    log.activity.toLowerCase().includes(searchTerm.toLowerCase())
                );
                setFilteredLogs(filtered);
            }
        };
        filterLogs();
    }, [searchTerm, logs]);

    useEffect(() => {
        const paginate = (items) => {
            const indexOfLastItem = currentPage * itemsPerPage;
            const indexOfFirstItem = indexOfLastItem - itemsPerPage;
            const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
            const maxPage = Math.ceil(items.length / itemsPerPage);

            return { currentItems, maxPage };
        };

        const { currentItems: paginatedItems, maxPage: paginatedMaxPage } = paginate(filteredLogs);
        setCurrentItems(paginatedItems);
        setMaxPage(paginatedMaxPage);
    }, [filteredLogs, currentPage]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to the first page when a search is performed
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= maxPage) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div
            id='Activity'
            className={`flex flex-col p-5 gap-5 ${isActiveSection ? 'border-blink' : 'ease-in duration-500 transition-all '}`}
        >
            <div className='flex items-center gap-2 text-2xl font-semibold border-b-2 border-blue-500 pb-2'>
                Account Activity
            </div>
            <div className='flex justify-between flex-col md:flex-row'>
                <p className='text-sm'>Check your account's latest activities on SocialFlick.</p>

                <div className='flex gap-2'>
                    <SearchInput value={searchTerm} onChange={handleSearchChange} />

                    {maxPage > 1 && (
                        <div className='self-end'>
                            <Pagination
                                currentPage={currentPage}
                                maxPage={maxPage}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className='flex flex-col space-y-4 min-h-[200px] max-w-full'>
                {currentItems.map((log, index) => {
                    const logDate = new Date(log.timestamp._seconds * 1000 + log.timestamp._nanoseconds / 1000000);
                    return (
                        <div
                            key={index}
                            className='flex max-w-full shadow border border-background rounded-lg p-4 items-start space-x-4'
                        >
                            <div className='flex flex-col items-center'>
                                <span className='text-blue-500 rounded-full text-lg font-medium'>
                                    {logDate.toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })}
                                </span>
                                <span className='text-[10px] italic'>
                                    {logDate.toLocaleTimeString('en-US', { timeStyle: 'short' })}
                                </span>
                            </div>
                            <div className='flex-1 min-w-0 max-w-[80%] max-h-[50px] overflow-hidden'>
                                <p className='text-sm font-medium'>{log.activity}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};


export default Settings
