import React, { useState } from "react";
import Chart from "react-apexcharts";
import DropdownButton from "../Tools/DropdownButton";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaEye } from "react-icons/fa";

const BarChart = ({ data }) => {
    const [isWeeklyView, setIsWeeklyView] = useState(false); // State to manage view toggle

    // Function to toggle between views
    const toggleView = () => {
        setIsWeeklyView(prevView => !prevView);
    };

    // Process the data to get counts
    const processData = (data, isWeeklyView) => {
        const now = new Date();
        const startDate = new Date(now);

        // Set the start date based on the view
        if (isWeeklyView) {
            startDate.setDate(now.getDate() - 7);
        } else {
            startDate.setDate(now.getDate() - 30);
        }

        let uploadedCount = 0;
        let scheduledCount = 0;
        let completedTodosCount = 0;

        data.forEach(item => {
            const itemDate = new Date(item.timestamp);
            // Filter data within the selected time range
            if (itemDate >= startDate && itemDate <= now) {
                if (item.action === 'Upload') {
                    uploadedCount += 1;
                } else if (item.action === 'scheduled') {
                    scheduledCount += 1;
                } else if (item.action === 'ToDo' && item.activity.startsWith('Completed:')) {
                    completedTodosCount += 1;
                }
            }
        });

        return {
            uploadedCount,
            scheduledCount,
            completedTodosCount
        };
    };

    const counts = processData(data, isWeeklyView);

    // Chart options using the counts
    const chartOptions = {
        series: [
            {
                name: 'Actual',
                data: [
                    {
                        x: 'Uploads',
                        y: counts.uploadedCount,
                        goals: [
                            {
                                name: 'Expected',
                                value: isWeeklyView ? 5 : 20, // Adjust expected value based on view
                                strokeWidth: 2,
                                strokeDashArray: 2,
                                strokeColor: '#775DD0'
                            }
                        ],
                    },
                    {
                        x: 'Scheduled',
                        y: counts.scheduledCount,
                    },
                    {
                        x: 'Todos',
                        y: counts.completedTodosCount,
                    }
                ]
            }
        ],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    distributed: true,
                },
            },
            colors: ['#00E396', '#eab308', '#3b82f6'],
            dataLabels: {
                formatter: function (val, opt) {
                    const goals = opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].goals;

                    if (goals && goals.length) {
                        return `${val} / ${goals[0].value}`;
                    }
                    return val;
                },
                textAnchor: 'start',
                style: {
                    colors: ['#fff']
                },
                background: {
                    enabled: false,
                },
                offsetX: 0,
            },
            xaxis: {
                labels: {
                    style: {
                        colors: ['#fff'],
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: ['#fff'],
                    },
                },
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                customLegendItems: ['Actual', 'Expected'],
                markers: {
                    fillColors: ['#00E396', '#775DD0']
                },
                labels: {
                    colors: '#fff',
                },
            },
            title: {
                text: isWeeklyView ? 'Weekly Statistics' : 'Monthly Statistics',
                align: 'center',
                style: {
                    fontSize: '20px',
                    fontWeight: '600',
                    fontFamily: 'Poppins, sans-serif',
                    letterSpacing: '0.5px',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                    color: 'var(--text-color)'
                },
            },
            grid: {
                borderColor: '#444',
            },
            tooltip: {
                theme: 'dark',
            },
            background: '#333', // Set the background color if needed
        },
    };

    return (
        <div className="relative">
            <div className="absolute top-0 right-0 z-10">
                <DropdownButton
                    label={<BsThreeDotsVertical size={20} />}
                    options={[
                        {
                            label: `${isWeeklyView ? 'Show Monthly View' : 'Show Weekly View'}`,
                            onClick: toggleView,
                            icon: <FaEye className="text-blue-600 font-bold" />
                        },
                    ]}
                />
            </div>
            <div id="chart">
                <Chart
                    options={chartOptions.options}
                    series={chartOptions.series}
                    type={chartOptions.options.chart.type}
                    width={'100%'}
                    height={300}
                />
            </div>
        </div>
    );
};

export default BarChart;
